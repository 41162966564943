<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  props: {
    images: Object,
    thumbnails: Object,
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '100'
    },
    css: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      index: 0,
      imgs: this.images,
      thumbs: this.thumbnails
    }
  },
  methods: {
    showImage(index) {
      this.index = index;
      this.visible = true;
    },
    hideImage() {
      this.visible = false;
    },
  },
};
</script>

<template>
  <div class="popup-gallery">
    <div v-if="images">
      <p v-for="(src, index) in thumbs"
          :key="index"
          class="d-inline-block me-2 mb-2"
          @click="() => showImage(index)"
      >
        <img thumbnail fluid :src="src" :width="width" :height="height" class="pointer" :class="css" alt="" />
      </p>
    </div>
    <vue-easy-lightbox
        loop="true"
        moveDisabled
        maxZoom="3"
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="hideImage"
    ></vue-easy-lightbox>
  </div>
</template>
